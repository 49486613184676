<template>
  <v-data-table :headers="headers" :items="listBank" @click:row="onClickRow">
    <template v-slot:top>
      <v-toolbar flat>
        <v-spacer></v-spacer>
        <v-btn color="success" dark rounded :to="{ name: 'Create Bank' }">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.no="{ item }">
      {{ listBank.findIndex(x => x === item) + 1 }}
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "bank",
  data: () => ({
    headers: [
      {
        text: "No",
        align: "start",
        sortable: false,
        value: "no",
      },
      { text: "Name", value: "name", sortable: false },
      { text: "Code", value: "code", sortable: false },
    ],
  }),

  created() {
    this.initialize();
  },

  computed: {
    ...mapState("bank", ["listBank"]),
  },

  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("bank/dropdown")
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    onClickRow(item) {
      this.$router.push({ name: "Detail Bank", params: { id: item.id } });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style></style>
